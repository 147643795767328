<template>
  <VRow wrap>
    <template v-if="modelHasId">
      <VCol
        v-if="mode === 'standalone'"
        cols="12"
        style="flex: 0 0 64px"
      >
        <ViewHeader>
          <!-- <template v-slot:extra-buttons>
            <Linkable
              v-if="!$isUserStudent"
              icon="check"
              tooltip="View Submissions"
              :copyable="false"
              :icon-props="{small: true}"
              :url="getFullyQualifiedUrlForItem(`${modelTypeRoute}.submitted`, {pipeline: model.id})"
              :to="getRouteForItem(`${modelTypeRoute}.submitted`, {pipeline: model.id})"
            />
          </template> -->
        </ViewHeader>
      </VCol>
      <VCol
        cols="12"
        style="flex: 1 1 auto"
      >
        <VRow
          row
          wrap
        >
          <VCol cols="12">
            <VCard class="pt-4">
              <VRow
                column
                align-center
                justify-center
              >
                <VAvatar :size="96">
                  <img :src="model.photo_url">
                </VAvatar>
                <h1>{{ model.name }}</h1>
              </VRow>
              <VCardText class="px-0">
                <VList
                  two-line
                  class="px-0"
                >
                  <VListItem>
                    <VListItemContent>
                      <VListItemTitle>Email</VListItemTitle>
                      <VListItemSubtitle>
                        <a :href="`mailto:${model.email}`">{{ model.email }}</a>
                      </VListItemSubtitle>
                    </VListItemContent>
                  </VListItem>
                  <VListItem>
                    <VListItemContent>
                      <VListItemTitle>Role</VListItemTitle>
                      <VListItemSubtitle>{{ model.team_user.role_name }}</VListItemSubtitle>
                    </VListItemContent>
                  </VListItem>
                  <VListItem v-if="model.city">
                    <VListItemContent>
                      <VListItemTitle>Location</VListItemTitle>
                      <VListItemSubtitle>{{ model.city.name }}</VListItemSubtitle>
                    </VListItemContent>
                  </VListItem>
                </VList>
                <VCard v-if="model.profile && model.profile.bio">
                  <VCardTitle>Bio</VCardTitle>
                  <VCardText>
                    <p
                      class="formatted"
                      v-html="model.profile.bio"
                    />
                  </VCardText>
                </VCard>
                <VCard>
                  <VCardTitle class="headline">
                    Associations and Submitted Information
                  </VCardTitle>
                  <VCardText v-if="!isAdmin(model.role.id)">
                    <ChipsDialog
                      :title="featureName('Programs')"
                      :models="model.programs"
                      component="ProgramView"
                    />
                  </VCardText>
                  <VAlert
                    v-else
                    :value="true"
                    type="info"
                  >
                    This is an admin user with access to all content
                  </VAlert>
                </VCard>
                <template v-if="hasOnboardingForm">
                  <VList two-line>
                    <VListItem>
                      <VListItemContent>
                        <h2>Onboarding response</h2>
                      </VListItemContent>
                    </VListItem>
                    <VListItem
                      v-for="(metadata, key) in model.current_team_onboarding_form.metadata"
                      :key="key"
                    >
                      <VListItemContent>
                        <VListItemTitle>{{ metadata.name }}</VListItemTitle>
                        <VListItemSubtitle>{{ metadata.value || "No response" }}</VListItemSubtitle>
                      </VListItemContent>
                    </VListItem>
                  </VList>
                </template>
                <VCard>
                  <VCardText class="pt-0 px-2">
                    <template v-if="applications.length">
                      <h3 class="headline px-0 mb-2">
                        {{ featureName('Applications') }} <VChip>{{ applications.length || 0 }}</VChip>
                      </h3>
                      <VExpansionPanels class="view-applications-container">
                        <VExpansionPanel
                          v-for="(application, index) in applications"
                          focusable
                          class="bg-transparent"
                          :key="`application-${index}`"
                        >
                          <VExpansionPanelHeader>
                            <div>{{ application.pipeline.title }}</div>
                          </VExpansionPanelHeader>
                          <VExpansionPanelContent class="light-grey pt-4 px-3">
                            <SubmittedApplication
                              mode="embedded"
                              :existing="application"
                            />
                          </VExpansionPanelContent>
                        </VExpansionPanel>
                      </VExpansionPanels>
                    </template>
                  </VCardText>
                  <VCardText>
                    <VExpansionPanels>
                      <VExpansionPanel
                        focusable
                        class="bg-transparent"
                      >
                        <VExpansionPanelHeader>
                          <h3 class="title">
                            {{ featureName('Deliverables') }} Submitted <VChip>{{ model.deliverables_submitted_count || 0 }}</VChip>
                          </h3>
                        </VExpansionPanelHeader>
                        <VExpansionPanelContent class="light-grey pt-2 px-0">
                          <DeliverablesSubmitted
                            for-single-user
                            mode="embedded"
                            deliverable-id="all"
                            :custom-filter="{
                              filter: {
                                submitted_by: model.id
                              }
                            }"
                          />
                        </VExpansionPanelContent>
                      </VExpansionPanel>
                    </VExpansionPanels>
                  </VCardText>
                  <VCardText>
                    <VExpansionPanels>
                      <VExpansionPanel
                        focusable
                        class="bg-transparent"
                      >
                        <VExpansionPanelHeader>
                          <h3 class="title">
                            {{ featureName('Lesson') }} Taken <VChip>{{ model.assessment_results_count || 0 }}</VChip>
                          </h3>
                        </VExpansionPanelHeader>
                        <VExpansionPanelContent class="light-grey pt-2 px-0">
                          <AssessmentsSubmitted
                            for-single-user
                            mode="embedded"
                            deliverable-id="all"
                            :custom-filter="{
                              filter: {
                                user_id: model.id
                              }
                            }"
                          />
                        </VExpansionPanelContent>
                      </VExpansionPanel>
                    </VExpansionPanels>
                  </VCardText>
                </VCard>
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
      </VCol>
    </template>
    <VCol v-else>
      <VRow
        row
        justify-center
      >
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import ViewMixin from "@/mixins/View";
import SubmittedApplication from "@/components/Organization/Pipelines/ViewSubmitted";
import DeliverablesSubmitted from "@/components/Deliverables/Submitted";
import AssessmentsSubmitted from "@/components/Lessons/Submitted";
import ViewHeader from "@/components/Elements/Navigation/ViewHeader";

export default {
  name: "UsersView",
  mixins: [ViewMixin],
  components: {
    SubmittedApplication,
    DeliverablesSubmitted,
    ViewHeader,
    AssessmentsSubmitted,
  },
  data() {
    return {
      modelType: "User",
    };
  },
  computed: {
    hasOnboardingForm() {
      return (
        this.model.current_team_onboarding_form &&
        this.model.current_team_onboarding_form.metadata &&
        this.model.current_team_onboarding_form.metadata.length
      );
    },
    applications() {
      if (this.model.submitted_application) {
        return [this.model.submitted_application];
      }
      return this.model.submitted_applications || [];
    },
  },
  methods: {
    ...mapActions({
      doGetUser: "users/getOne",
    }),
    onGet(id) {
      return this.doGetUser({
        id,
        _fields: "all",
        _relations: [
          "submittedApplication.stages",
          "primaryContentCompleted",
          "deliverablesSubmitted",
        ],
      });
    },
    isAdmin(role) {
      return ["admin", "owner", "organization-admin"].indexOf(role) > -1;
    },
  },
};
</script>
<style lang="scss">
.view-applications-container {
  .v-expansion-panel-content__wrap {
    padding: 0px !important;
  }
}
</style>

